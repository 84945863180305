/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { makeHandle as e } from "../../core/handleUtils.js";
import { watch as t, syncAndInitial as n } from "../../core/reactiveUtils.js";
const r = new WeakMap();
function a(o) {
  return !o.destroyed && (r.has(o) || o.addHandles([t(() => {
    const e = o.parent;
    return !(!e || !("type" in e)) && ("catalog-dynamic-group" === e.type || a(e));
  }, e => r.set(o, e), n), e(() => r.delete(o))]), r.get(o));
}
function o(e) {
  return e.parent && "type" in e.parent && "catalog-dynamic-group" === e.parent.type ? e.parent.parent : null;
}
export { o as getCatalogLayerForLayer, a as isLayerFromCatalog };